<template>
    <nav class="fixed z-50 top-0 flex shadow-xl w-full items-center justify-between flex-no-wrap bg-gradient-to-r from-neutral-700 to-primary-500 h-[70px]">
        <div class="flex flex-row">
          <img :src="traqKingLogo" width="70"/>
          <img :src="traqKingDark" width="100"/>
        </div>
        <div class="flex flex-row w-full justify-end">
            <div class="h-0 w-0 border-t-warning-500 border-l-transparent border-t-[70px] border-l-[70px]"></div>
            <div class="px-5 bg-warning-500 flex flex-row items-center justify-end w-25%"></div>
        </div>
    </nav>
</template>

<script>

import * as heroIcons from '@heroicons/vue/24/outline'

export default ({
  name: 'NavbarVue',
  data(){
    return {
      heroIcons: heroIcons,
      traqKingLogo: require('@/assets/traq-king-logo.svg'),
      traqKingDark: require('@/assets/traq-king-dark.svg'),
    }
  },
  watch:{
    '$router.currentRoute.value.fullPath':{
      handler(){},
      deep: true,
      immediate: true
    },
  }

})

</script>
