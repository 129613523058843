<template>
  <div class="bg-gray-50">
    <NavbarVue></NavbarVue>
    <div class="font-lato mx-auto absolute top-[70px] w-full">
      <router-view/>
    </div>
  </div>
</template>

<script>

import NavbarVue from './components/NavbarVue.vue';

export default {
  name: 'App',
  components: {
    NavbarVue
  },  
}
</script>

