<template>
    <div class="flex flex-col h-[calc(100vh-70px)] justify-start items-center !bg-gray-200 select-none">
        <div class="flex flex-col bg-gray-50 shadow-xl p-4 w-full">
            <div class="w-full flex flex-row mb-4 justify-between items-end">
                <InputVue
                class="w-full"
                v-bind:modelValue="$addressDetails ? ($addressDetails.addressLine1 ? $addressDetails.addressLine1 + ', ' : '') + ($addressDetails.zipcode ? $addressDetails.zipcode + ' ' : '') + ($addressDetails.city ? $addressDetails.city : '') : null" 
                :height="'large'" 
                :label="'Rechercher une adresse'" 
                :placeholder="'Position Courante'"
                :state="'disabled'"
                />
                <ButtonVue class="ml-2"
                :label="null"
                :filling="'filled'"
                :size="'large'"
                :icon="'MapPinIcon'"
                :state="'default'"
                />
            </div>
        </div>
        <div v-if="!scanListId" class="flex flex-col items-center justify-center w-full px-10">
            <span class="text-title-1 my-10">La resource que vous recherché n'existe pas</span>
            <span class="text-body-2 my-2">Vous allez être redirigé vers la page d'acceuil</span>
        </div>
        <div v-else class="flex flex-col items-center justify-center w-full">
            <LoadingVue v-if="!scanList"/>
            <div v-else class="w-full flex flex-col items-center justify-start pt-6">
                <span v-if="otherScans.length" class="text-title-2 mb-6 w-full px-4">Dernier Scan</span>
                <div class="w-full bg-gray-50 shadow-lg flex flex-row items-center justify-start py-4 px-2 relative z-10"
                :class="displayScan.id == scanId && otherScans.length ? 'ring-primary-400 ring-offset-2 ring-2 ring-inset' : null"
                @click="() => {
                    scan = displayScan
                    scanId = displayScan.id
                }">
                    <div class="pl-4 pb-4">
                        <TagVue :type="'traqr'" :alert="displayScan.report ? displayScan.report.alert : null" :state="displayScan.traqr.isScanned ? 'delivered' : null"/>
                    </div>
                    <div class="flex flex-col items-start justify-center ml-2">
                        <span class="text-title-3">{{ displayScan.traqr.reference }}</span>
                        <span class="text-body-3 text-gray-600">{{displayScan.goods ? displayScan.goods : 'Nombre de références :' + displayScan.traqr.handlingUnitsNumber }}</span>
                    </div>
                </div>
                <div v-for="(handlingUnit, index) in displayScan.handlingUnits.slice(0, 5)" :key="index" class="w-full bg-gray-50 shadow-md flex flex-row items-center justify-start py-4 px-2 border-b">
                    <div class="pl-4 pb-4">
                        <TagVue :type="'handlingUnit'" :state="handlingUnit.isScanned ? 'delivered' : null"/>
                    </div>
                    <div class="flex flex-col items-start justify-center ml-2">
                        <span class="text-body-1">{{ handlingUnit.reference }}</span>
                        <span class="text-body-5 text-gray-600"> {{ handlingUnit.description }}</span>
                    </div>
                </div>
                <div v-if="displayScan.handlingUnits.length > 5" class="w-full bg-gray-50 shadow-lg flex flex-row items-center justify-center py-4 px-2 border-b">
                    + {{ displayScan.handlingUnits.length - 5 }} références ...
                </div>
            </div>
            <div v-if="otherScans.length" class="w-full flex flex-col items-center justify-start pt-10 bg-gray-200">
                <div class="w-full flex flex-row justify-between items-center px-4 my-6" @click="openDropDown = !openDropDown">
                    <span class="text-title-2">Scans précédents (30 min)</span>
                    <component :is="openDropDown ? $heroIcons['ChevronUpIcon'] : $heroIcons['ChevronDownIcon']" class="stroke-black h-8 w-8"/>
                </div>
                <div v-show="openDropDown" class="w-full flex flex-col items-center justify-start mt-2"
                v-for="(currentScan, index) in otherScans" :key="index"
                @click="() => {
                    scan = currentScan
                    scanId = currentScan.id
                }">
                    <div class="w-full bg-gray-50 shadow-lg flex flex-row items-center justify-start py-4 px-2 relative z-10"
                    :class="currentScan.id == scanId ? 'ring-primary-400 ring-offset-2 ring-2 ring-inset' : null">
                        <div class="pl-4 pb-4"> 
                            <TagVue :type="'traqr'" :alert="currentScan.report ? currentScan.report.alert : null" :state="currentScan.traqr.isScanned ? 'delivered' : null"/>
                        </div>
                        <div class="flex flex-col items-start justify-center ml-2">
                            <span class="text-title-3">{{ currentScan.traqr.reference }}</span>
                            <span class="text-body-3 text-gray-600">{{currentScan.goods ? currentScan.goods : 'Nombre de références :' + currentScan.traqr.handlingUnitsNumber }}</span>
                        </div>
                    </div>
                    <div v-for="(handlingUnit, index2) in currentScan.handlingUnits" :key="index2" class="w-full bg-gray-50 shadow-md flex flex-row items-center justify-start py-4 px-2 border-b">
                        <div class="pl-4 pb-4">
                            <TagVue :type="'handlingUnit'" :state="handlingUnit.isScanned ? 'delivered' : null"/>
                        </div>
                        <div class="flex flex-col items-start justify-center ml-2">
                            <span class="text-body-1">{{ handlingUnit.reference }}</span>
                            <span class="text-body-5 text-gray-600"> {{ handlingUnit.description }}</span>
                        </div>
                    </div>
                    <div v-if="currentScan.handlingUnits.length > 5" class="w-full bg-gray-50 shadow-md flex flex-row items-center justify-start py-4 px-2 border-b">
                        + {{ currentScan.handlingUnits.length - 5 }} références ...
                    </div>
                </div>
                <div class="h-[20vh] w-full !bg-gray-200"></div>
            </div>
        </div>
        <div class="fixed w-full bottom-0 flex flex-col p-4 bg-white shadow-lg z-20">
            <span v-if="scan && otherScans.length" class="text-body-2 flex justify-center w-full mb-4 mx-auto">Référence sélectionnée : {{ scan.traqr.reference }}</span>
            <ButtonVue class="mb-4"
            :label="'Livraison OK - Scan POD / Récépissé'"
            :filling="'filled'"
            :size="'large'"
            :icon="'ClipboardDocumentCheckIcon'"
            :icon_position="'left'"
            :state="'success'"
            @click="confirm('/validation/'+scanId+'/'+scanListId)"
            />
            <div class="flex flex-row w-full">
                <ButtonVue class="mr-2 w-50%"
                :label="'Problemes'"
                :filling="'filled'"
                :size="'large'"
                :icon="'ExclamationTriangleIcon'"
                :icon_position="'left'"
                :state="'warning'"
                @click="confirm('/issue/'+scanId+'/'+scanListId)"
                />
                <ButtonVue class="ml-2 w-50%"
                :label="'Litige'"
                :filling="'filled'"
                :size="'large'"
                :icon="'XCircleIcon'"
                :icon_position="'left'"
                :state="'danger'"
                @click="confirm('/damage/'+scanId+'/'+scanListId)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import * as heroIcons from '@heroicons/vue/24/outline'

import InputVue from '@/components/shared/elements/Input.vue';
import LoadingVue from '@/components/shared/elements/Loading.vue';
import ButtonVue from '@/components/shared/elements/Button.vue';
import TagVue from '@/components/shared/elements/Tag.vue';

export default ({
    name: 'SummaryView',
    components:{
        LoadingVue,
        InputVue,
        ButtonVue,
        TagVue
    },
    data(){
        return {
            heroIcons: heroIcons,
            scanListId: null,
            scanList: null,
            address: null,
            scanId: null,
            scan: null,
            displayScan: null,
            otherScans: [],
            openDropDown: true
        }
    },
    async mounted() {
        this.$route.params.scanListId ? this.scanListId = this.$route.params.scanListId : null;
        if(!this.scanListId){
            setTimeout(() => {
                this.$router.replace('/')
            }, 5000)
        }
        await this.$axios
        .get(process.env.VUE_APP_API_BASE_PATH+'/scan_lists/'+this.scanListId, {  withCredentials: true })
        .then((response) => {
            console.log('[SUCCESS] SCANLIST GET')
            this.scanList = response.data
            this.$global.$addressDetails = this.scanList.address
            const sortedScan = this.scanList.scans.sort((a, b) => new Date(b.updatable.createdAt).getTime() - new Date(a.updatable.createdAt).getTime())

            this.displayScan = this.scanForDisplay(sortedScan[0])
            this.scanId = this.displayScan.id
            this.scan = this.displayScan
            sortedScan.shift()
            sortedScan.forEach(element => {
                this.otherScans.push(this.scanForDisplay(element))
            })
        })
        .catch(() =>{
            console.log('[ERROR] SCANLIST GET')
        });


    },
    methods: {
        scanForDisplay(scan){
            let displayScan = null
            if(scan.resource['@type'] == 'Traqr'){
                displayScan = {
                    id: scan.id,
                    report: scan.report ? {
                        state: scan.report.state,
                        alert: this.getAlertType(scan.report.state.split('-')[0]),
                        id: scan.report.id,
                    }: null,
                    goods: scan.resource.goods,
                    traqr: {
                        reference: scan.resource.customReferences[scan.resource.customReferences.findIndex(p => p.type == "ILU")].reference,
                        handlingUnitsNumber: scan.resource.handlingUnits.length,
                        isScanned: true
                    }
                }
                displayScan.handlingUnits = scan.resource.handlingUnits.map(element => {
                    return {
                        reference: element.reference,
                        description: 'Poids : '+(element.weight ? element.weight : 'N/A')+" - Lxlxh : "+(element.length ? element.length : 'N/A')+"x"+(element.width ? element.width : 'N/A')+"x"+(element.height ? element.height : 'N/A'),
                        isScanned: false
                    }
                })
            } else if(scan.resource['@type'] == 'HandlingUnit'){
                displayScan = {
                    id: scan.id,
                    report: scan.report ? {
                        state: scan.report.state,
                        alert: this.getAlertType(scan.report.state.split('-')[0]),
                        id: scan.report.id,
                    }: null,
                    goods: scan.resource.traqr.goods,
                    traqr: {
                        reference: scan.resource.traqr.customReferences[scan.resource.traqr.customReferences.findIndex(p => p.type == "ILU")].reference,
                        handlingUnitsNumber: scan.resource.traqr.handlingUnits.length,
                        isScanned: false
                    }
                }

                displayScan.handlingUnits = scan.resource.traqr.handlingUnits.map(element => {
                    if(element.reference !== undefined){
                        return {
                            reference: element.reference,
                            description: 'Poids : '+(element.weight ? element.weight : 'N/A')+" - Lxlxh : "+(element.length ? element.length : 'N/A')+"x"+(element.width ? element.width : 'N/A')+"x"+(element.height ? element.height : 'N/A'),
                            isScanned: false
                        }
                    }
                })
                displayScan.handlingUnits = displayScan.handlingUnits.filter(function( element ) {
                    return element !== undefined;
                });
                displayScan.handlingUnits.unshift({
                    reference: scan.resource.reference,
                    description: 'Poids : '+(scan.resource.weight ? scan.resource.weight : 'N/A')+" - Lxlxh : "+(scan.resource.length ? scan.resource.length : 'N/A')+"x"+(scan.resource.width ? scan.resource.width : 'N/A')+"x"+(scan.resource.height ? scan.resource.height : 'N/A'),
                    isScanned: true
                })
            }
            //displayScan.handlingUnits = displayScan.handlingUnits.slice(0,5)
            return displayScan;
        },
        getAlertType(string){
            switch (string){
                case 'validation': return 'success'
                case 'issue': return 'warning'
                case 'damage': return 'danger'
            }
        },
        confirm(path){
            if(this.scan.report){
                if (window.confirm('La création d\'un nouveau rapport écrasera le précédent. Êtes vous sûr de vouloir supprimer les informations déjà renseignées ?')){
                    this.$router.push(path)
                }
            }else{
                this.$router.push(path)
            }
        }
    }
})
</script>