<template>
    <div class="flex flex-col h-[calc(100vh-70px)] justify-start items-center !bg-gray-200 p-4">
        <div class="w-full flex flex-col items-start justify-center">
            <div class="w-full flex flex-row my-4">
                <input type="file" 
                @input="savePod($event)"
                id="pod"
                accept="image/*,.pdf"
                class="hidden"/>
                <input type="file"
                id="delivery"
                @input="saveDelivery($event)"
                accept="image/*,.pdf"
                class="hidden"/>
                <ButtonVue class="mr-2 w-50%"
                :label="'Scan POD'"
                :filling="'filled'"
                :size="'medium'"
                :icon="'ClipboardDocumentCheckIcon'"
                :icon_position="'left'"
                :state="'warning'"
                @click="openPod()"
                />
                <ButtonVue class="ml-2 w-50%"
                :label="'Photos Livraison'"
                :filling="'filled'"
                :size="'medium'"
                :icon="'CameraIcon'"
                :icon_position="'left'"
                :state="'warning'"
                @click="openDelivery()"
                />
            </div> 
            <div class="flex flex-row justify-end">
                <div  v-if="loadingPhoto" class="fixed z-50 top-[170px] left-[50%] -translate-x-1/2 bg-gray-50 shadow-xl p-4 rounded-modal">
                    <LoadingVue/>
                </div>
                <div v-for="(asset,index) in assets" :key="index" class="h-full">
                    <div v-if="asset.type !== 'SIGNATURE'" class="mx-2 relative">
                        <img :src="asset.url" class="object-cover h-[60px] w-[60px] rounded-modal shadow-lg" alt="">
                        <div class="bg-danger-500 text-white top-0 right-0 translate-x-1/2 -translate-y-1/2 absolute rounded-infinity p-1">
                            <svg class="w-3 h-3" 
                            xmlns="http://www.w3.org/2000/svg" 
                            fill="none" 
                            viewBox="0 0 24 24" 
                            stroke-width="1.5" 
                            stroke="currentColor" 
                            @click="deleteImage(index)">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col items-start justify-center mt-10">
                <TextVue
                class="w-full"
                v-model="commentShipper" 
                :height="'large'" 
                :label="'Décrivez nous le problème'" 
                :placeholder="'Votre soucis ...'"
                :state="'default'"
                />
            </div>
        </div>
        <div class="px-4 w-full fixed bottom-4">
            <ButtonVue class="w-full"
            :label="'Validation'"
            :filling="'filled'"
            :size="'large'"
            :icon="'CheckIcon'"
            :icon_position="'left'"
            :state="stateButton"
            @click="validateReport()"
            />
        </div>
    </div>
</template>

<script>
import ButtonVue from '@/components/shared/elements/Button.vue';
import TextVue from '@/components/shared/elements/Text.vue';
import LoadingVue from '@/components/shared/elements/Loading.vue';
import imageCompression from "browser-image-compression";

export default ({
    name: 'DamageView',
    components:{
        ButtonVue,
        TextVue,
        LoadingVue
    },
    data(){
        return {
            assets: [],
            imgs: [],
            stateButton: "disabled",
            reportId: null,
            scanListId: null,
            scanId: null,
            commentShipper: "",
            loadingPhoto: false,
        }
    },
    async created(){
        this.$pauseGeoloc()
        this.$route.params.scanListId ? this.scanListId = this.$route.params.scanListId : null;
        this.$route.params.scanId ? this.scanId = this.$route.params.scanId : null;
        this.scanId && this.scanListId ? await this.createReport() : this.$router.replace('/notfound');
    },
    methods:{
        openPod(){
            document.getElementById('pod').click()
        },

        openDelivery(){
            document.getElementById('delivery').click()
        },

        async createReport(){
            await this.$axios.post(process.env.VUE_APP_API_BASE_PATH + '/reports',
            {

                'state': 'issue-created',
                'scan': '/api/scans/' + this.scanId
            },
            {
                withCredentials:true,
                headers: {'Content-type': 'application/ld+json'}
            })
            .then((response) => {
                this.reportId = response.data.id;
            })
            .catch((error) => {
                this.reportId = error.response.data.id;
            })
        },

        async updateReport(){
            const headers = {
            'Content-type': 'application/merge-patch+json',
            }
            var json = {
                'state': 'issue-pending',
                'medias': this.assets,
                'commentShipper': this.commentShipper,
            };
            await this.$axios
            .patch(process.env.VUE_APP_API_BASE_PATH+'/reports/'+ this.reportId,json,{withCredentials:true,headers: headers});
        },

        async validateReport(){
            const headers = {
            'Content-type': 'application/merge-patch+json',
            }
            var json = {
                'state': 'issue-closed',
            };
            await this.$axios
            .patch(process.env.VUE_APP_API_BASE_PATH+'/reports/'+ this.reportId,json,{withCredentials:true,headers: headers}).then(()=>{this.$router.replace('/summary/'+this.scanListId);});
        },

        async savePod(a){
            let newFile =  a.target.files[0];
            this.loadingPhoto = true
            const reader = new FileReader();
            reader.readAsDataURL(newFile); // read file as data url
            reader.onload = async () => { // called once readAsDataURL is completed
                if(newFile.type.startsWith('image')){
                    await this.sendCompressedFile(newFile, 'POD')
                } else {
                    await this.sendFile(newFile, 'POD')
                }
            }
        },

        async saveDelivery(a){
            let newFile =  a.target.files[0];
            this.loadingPhoto = true
            const reader = new FileReader();
            reader.readAsDataURL(newFile); // read file as data url
            reader.onload = async () => { // called once readAsDataURL is completed
                if(newFile.type.startsWith('image')){
                    await this.sendCompressedFile(newFile, 'DELIVERY')
                } else {
                    await this.sendFile(newFile, 'DELIVERY')
                }
            }
        },

        async deleteImage(index){
            this.assets.splice(index,1)
            this.imgs.splice(index,1)
        },

        isPdf(url){
          let array = url.split('.')
          return array[array.length - 1] === 'pdf'
        },

        async sendCompressedFile(newFile, type){
            let baseUrl = process.env.VUE_APP_API_BASE_PATH+''
            baseUrl = baseUrl.substring(0, baseUrl.length - 3)
            const options = {
                    maxSizeMB: 2,
                    useWebWorker: true
                };
            await imageCompression(newFile, options)
                .then(async (img) => {
                    const fileToSend = new File([img], newFile.name, {type: newFile.type})
                    let formData = new FormData();
                    formData.append('file',  fileToSend);
                    await this.$axios
                    .post(process.env.VUE_APP_API_BASE_PATH+'/media_objects',
                    formData, 
                    {
                        withCredentials:true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(async response => {
                        console.log(response.data)
                        this.loadingPhoto = false
                        if(response.status == 201){
                            this.assets.push({
                                type: type,
                                url: baseUrl + response.data.contentUrl
                            })
                            this.loadingPhoto = false
                            this.updateReport()
                        }
                    })
                    .catch(response =>{
                        console.log(response)
                    });
                });
        },

        async sendFile(newFile, type){
            let baseUrl = process.env.VUE_APP_API_BASE_PATH+''
            baseUrl = baseUrl.substring(0, baseUrl.length - 3)
            let formData = new FormData();
            formData.append('file', newFile);
            await this.$axios
            .post(process.env.VUE_APP_API_BASE_PATH+'/media_objects',
            formData, 
            {
                withCredentials:true,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(async response => {
                console.log(response.data)
                if(response.status == 201){
                    this.assets.push({
                        type: type,
                        url: baseUrl + response.data.contentUrl
                    })
                    this.loadingPhoto = false
                    this.updateReport()
                }
            })
            .catch(response =>{
                console.log(response)
            });
        }
    },
    watch:{
        commentShipper:{
            handler(){
                this.commentShipper.length > 20 ? this.stateButton = 'warning' : this.stateButton = 'disabled'
                clearTimeout(this.timeOut)
                this.timeOut = setTimeout(() => {
                    this.updateReport()
                }, 3000)
            },
            deep: true
        },

        assets:{
            handler(){
                this.imgs = this.assets.map(element => {if(element.type !== "SIGNATURE") return element.url})
                this.imgs.filter(function( element ) {
                    return element !== undefined;
                });
                this.commentShipper.length > 20 ? this.stateButton = 'warning' : this.stateButton = 'disabled'
            },
            deep: true
        }
    }
})
</script>