import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './style.css'
//import {fpjsPlugin} from '@fingerprintjs/fingerprintjs-pro-vue-v3';
import { useGeolocation } from '@vueuse/core'
import VueSignaturePad from 'vue-signature-pad';
import * as heroIcons  from "@heroicons/vue/24/outline"
import { styles } from '@/components/shared/lib/styles'

const { coords , pause, resume, error} = useGeolocation({
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  })

const app = createApp(App)

app.config.globalProperties.$axios = axios
app.config.globalProperties.$coords = coords
app.config.globalProperties.$geolocationError = error
app.config.globalProperties.$pauseGeoloc = pause
app.config.globalProperties.$resumeGeoloc = resume
app.config.globalProperties.$addressDetails = null
app.config.globalProperties.$global = app.config.globalProperties
app.config.globalProperties.$heroIcons = heroIcons
app.config.globalProperties.$styles = styles
/*
app.use(fpjsPlugin, {
    loadOptions: {
      apiKey: "5427WqWGMMH3lWDL9IZO",
      region: "eu"
    },
  })
*/

app.use(router)
app.use(VueSignaturePad)

app.mount('#app')
