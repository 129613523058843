<template>
     <div class="flex flex-col h-[calc(100vh-70px)] justify-center items-center">
        <div v-if="!visitorId && loadingState == 1" class="flex flex-col justify-center items-center ">
            <img :src="adBlockLogo" width="70" class="my-10"/>
            <span class="text-title-1 my-10">Pour le bon fonctionnement de l'application</span>
            <span class="text-title-3">Vous êtes prié de désactiver <span class="text-title-2">AdBlock</span> sur ce site ou tout autre bloqueur de publicités</span>
        </div>
        <div v-else-if="!cookieSet && loadingState == 2" class="flex flex-col">
            <span class="text-title-1 my-10">ERREUR</span>
            <span class="text-title-3">La connexion avec le serveur a été interrompue, veuillez réessayer dans quelques instants</span>
            <span>{{ visitorId }}</span>
        </div>
        <div v-else-if="!scanListId && loadingState == 3" class="flex flex-col">
            <span class="text-title-1 my-10">Numéro de scanner non reconnu !</span>
            <span class="text-title-3">Assurez vous que le QR Code correspond bien à une expédition valide</span>
        </div>
        <div v-else>
            <LoadingVue></LoadingVue>
        </div>
    </div>
</template>

<script>

import LoadingVue from '@/components/shared/elements/Loading.vue';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

export default ({
    name: 'ScannerView',
    components:{
        LoadingVue
    },
    data(){
        return {
            adBlockLogo: require('@/assets/adblock/adblock-logo.png'),
            visitorData: null,
            visitorId: null,
            scannerId: null,
            scanListId: null,
            cookieSet: null,
            loadingState: 0,
        }
    },
    async mounted() {
        this.$route.params.scannerId ? this.scannerId = this.$route.params.scannerId : null;
        await getCurrentBrowserFingerPrint().then((fingerprint) => {
            console.log(fingerprint)
            this.loadingState = 1
            this.visitorId = fingerprint+''
            console.log( "[SUCCESS] VISITOR")
        })
        /*try {
            this.visitorData = await this.$fpjs.getVisitorData({
                extendedResult: true
            })
        } catch (error) {
            console.error(error);
        }
        if(this.visitorData){
            this.loadingState = 1
            this.visitorId = this.visitorData.visitorId
            console.log( "[SUCCESS] VISITOR")
        } else {
            this.loadingState = 1
            console.log( "[ERROR] VISITOR")
        }*/
        if(this.visitorId && this.loadingState){
            await this.$axios
            .post(process.env.VUE_APP_API_BASE_PATH+'/visitors/connect', 
            {fingerprint: this.visitorId},{withCredentials: true,
                headers: {
                'Content-Type': 'application/ld+json',
            }})
            .then(() => {
                this.cookieSet = true
                this.loadingState = 2
                console.log( "[SUCCESS] CONNECT")
            })
            .catch(() => {
                this.loadingState = 2
                console.log( "[ERROR] CONNECT")
            })
            if(this.cookieSet){
                await this.$axios
                .get(process.env.VUE_APP_API_BASE_PATH+'/scanners/'+this.scannerId,{withCredentials: true})
                .then((response) => {
                    console.log(response)
                    this.scanListId = response.data.scanList.split('/').slice(-1)
                    this.loadingState = 3
                    console.log( "[SUCCESS] SCANNER")
                    this.$router.replace('/geolocation/'+ this.scanListId)
                })
                .catch(() => {
                    this.loadingState = 3
                    console.log( "[ERROR] SCANNER")
                })
            }
        }
    },

})
</script>
