<template>
     <div class="flex flex-col h-[calc(100vh-70px)] justify-start items-center px-10 mt-10">
        <span class="text-title-1 my-10">Merci d'avoir utiliser</span>
        <img :src="traqKingLogo" width="120" class="my-4"/>
        <img :src="traqKingDark" width="200"/>
    </div>
</template>

<script>

export default ({
    name: 'ThanksView',
    data(){
        return {
        traqKingLogo: require('@/assets/traq-king-logo.svg'),
        traqKingDark: require('@/assets/traq-king-light.svg'),
        }
    },
    created(){
        this.$pauseGeoloc()
        setTimeout(() => {
            this.$router.replace('/')
        }, 5000)
    }
})
</script>