<template>
    <div class="flex flex-col h-[calc(100vh-70px)] justify-start items-center !bg-gray-100">
        <div class="flex flex-col bg-gray-50 shadow-xl p-4 w-full">
            <div class="w-full flex flex-row mb-4 justify-between"
            :class="addressEditable ? 'items-center' : 'items-end'">
                <AutocompletVue
                class="w-full"
                v-model="address" 
                :height="'large'" 
                :label="'Rechercher une adresse'" 
                :placeholder="'Position Courante'"
                :objects="addresses" 
                :disabled="addressEditable ? false : true"
                :state="addressEditable ? 'warning' : 'default'"
                :message="addressEditable ? 'Veuillez remplir le champ ci dessus' : null"
                @setValue="addressEditable ? setAddress($event) : null"
                />
                <ButtonVue class="ml-2"
                :label="null"
                :filling="'filled'"
                :size="'large'"
                :icon="'MapPinIcon'"
                :state="'default'"
                />
            </div>
            <div v-if="coordinatesIdle && !this.addressEditable">
                <LoadingVue></LoadingVue>
            </div>
        </div>
        <div v-if="!scanListId" class="flex flex-col items-center justify-center w-full px-10">
            <span class="text-title-1 my-10">La resource que vous recherché n'existe pas</span>
            <span class="text-body-2 my-2">Vous allez être redirigé vers la page d'acceuil</span>
        </div>
        <div v-if="geolocationState == 'PERMISSION_DENIED'" class="flex flex-col items-center justify-center w-full px-10">
            <span class="text-title-3 my-10">Veuillez paramétrer le partage de position avec votre navigateur</span>
            <span class="text-body-2 my-2">Puis rechargez la page</span>
        </div>
    </div>
</template>

<script>

import * as heroIcons from '@heroicons/vue/24/outline'

import AutocompletVue from '@/components/shared/elements/Autocomplete.vue';
import LoadingVue from '@/components/shared/elements/Loading.vue';
import ButtonVue from '@/components/shared/elements/Button.vue';

export default ({
    name: 'GeolocationView',
    components:{
        LoadingVue,
        AutocompletVue,
        ButtonVue
    },
    data(){
        return {
            heroIcons: heroIcons,
            coordinates: null,
            coordinatesIdle: true,
            scanListId: null,
            addresses: [],
            sessionToken: null,
            address: null,
            addressDetails: null,
            addressEditable: false,
            geolocationState: "",
        }
    },
    async mounted() {
        this.$resumeGeoloc()
        this.$route.params.scanListId ? this.scanListId = this.$route.params.scanListId : null;
        if(!this.scanListId){
            setTimeout(() => {
                this.$router.replace('/')
            }, 5000)
        }
    },

    methods: {
        async searchAddress(){
            var query = process.env.VUE_APP_API_BASE_PATH+'/addresses_autocomplete?input='+this.address
            if(this.sessionToken){
                query = query +'&sessiontoken='+this.sessionToken
            }
            await this.$axios
            .get(query, {  withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    this.sessionToken = response.data.sessiontoken
                    this.addresses = response.data.predictions
                }
            })
            .catch(() =>{
                this.addresses = []
            });
        },
        async setAddress(object) {
            this.address = object.description
            await this.$axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/address_details?place_id='+object.place_id+'&sessiontoken='+this.sessionToken, {  withCredentials: true })
            .then(response => {
                if(response.status == 200){
                    this.$global.$addressDetails = response.data
                    this.address = this.addressDetails = this.$addressDetails.addressLine1 + ", " + this.$addressDetails.zipcode + " " + this.$addressDetails.city
                    this.sessionToken = null
                    this.addresses = []
                    this.updateScanList()
                }
            })
            .catch(() =>{
                this.addresses = []
            });
        },

        async updateScanList() {
            const options = {
                withCredentials: true,
                headers: {
                    'Content-Type' : 'application/merge-patch+json'
                }
            }
            const content = {
                address: '/api/addresses/'+this.$addressDetails.id
            }
            await this.$axios
            .patch(process.env.VUE_APP_API_BASE_PATH+'/scan_lists/'+this.scanListId, 
            content, options)
            .then(() => {
                console.log('[SUCCESS] SCANLIST UPDATE ADDRESS')
                this.addressEditable = false
                this.$router.replace('/summary/'+ this.scanListId)
            })
            .catch(() =>{
                console.log('[ERROR] SCANLIST UPDATE ADDRESS')
            });
        }
    },
    watch:{
        '$coords':{
            handler(){
                if(this.$coords.value.latitude !== Infinity && this.$coords.value.longitude !== Infinity){
                    console.log('[SUCCESS] COORDINATES')
                    this.coordinates =  {
                        latitude: this.$coords.value.latitude,
                        longitude: this.$coords.value.longitude,
                    }
                    this.$pauseGeoloc()
                    this.$axios
                    .get(process.env.VUE_APP_API_BASE_PATH+'/address_reverse?lat='+this.coordinates.latitude+'&long='+this.coordinates.longitude, {  withCredentials: true })
                    .then(response => {
                        console.log('[SUCCESS] ADDRESS')
                        this.$global.$addressDetails = response.data
                        this.address = this.addressDetails = this.$addressDetails.addressLine1 + ", " + this.$addressDetails.zipcode + " " + this.$addressDetails.city
                        this.coordinatesIdle = false
                        this.updateScanList()
                    })
                    .catch(() =>{
                        console.log('[ERROR] ADDRESS')
                    });

                }
            },
            deep: true,
            immediate: true
        },
        '$geolocationError':{
            handler(){
                if(this.$geolocationError.value !== null){
                    console.log('[GEOLOCATION] isn\'t supported')
                    this.coordinatesIdle = false
                    this.$geolocationError.value.PERMISSION_DENIED == 1 ? this.addressEditable = true : null
                    this.geolocationState = "PERMISSION_DENIED"
                } else {
                    console.log('[GEOLOCATION] is supported')
                    this.geolocationState = "PERMISSION_APPROVED"
                    setTimeout(() => {
                        if(!this.address){
                            this.addressEditable = true
                        }
                    }, 10000)
                }
            },
            deep: true,
            immediate: true
        },
        address:{
            handler(){
                if(this.addressEditable && !this.$addressDetails){
                    if(this.address){
                        this.searchAddress()
                    }
                }
            },
            deep: true,
            immediate: true
        }
  }
})
</script>