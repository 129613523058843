import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import ScannerView from '../views/ScannerView.vue'
import GeolocationView from '../views/GeolocationView.vue'
import SummaryView from '../views/SummaryView.vue'
import ValidationView from '../views/ValidationView.vue'
import DamageView from '../views/DamageView.vue'
import IssueView from '../views/IssueView.vue'
import ThanksView from '../views/ThanksView.vue'
import PageNotFoundView from '../views/PageNotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'Acceuil',
    component: HomeView
  },
  {
    path: '/scanner/:scannerId',
    name: 'Connexion',
    component: ScannerView
  },
  {
    path: '/geolocation/:scanListId', 
    name: 'Geolocalisation',
    component: GeolocationView
  },
  {
    path: '/summary/:scanListId', 
    name: 'Résumé',
    component: SummaryView,
    props: true
  },
  {
    path: '/validation/:scanId/:scanListId', 
    name: 'Validation',
    component: ValidationView
  },
  {
    path: '/damage/:scanId/:scanListId', 
    name: 'Litige',
    component: DamageView
  },
  {
    path: '/issue/:scanId/:scanListId', 
    name: 'Problème',
    component: IssueView
  },
  {
    path: '/thanks', 
    component: ThanksView
  },
  {
    path: '/:catchAll(.*)*',
    name: "Page Not Found",
    component: PageNotFoundView
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
