<template>
    <div class="flex flex-col h-[calc(100vh-70px)] justify-start items-center px-10 mt-10">
        <span class="text-title-2 my-4">Bienvenue sur le Scanner de TraQKING</span>
        <span class="text-body-2 my-2">Cet outil a pour vocation de faciliter le partage d'informations avec l'exploitation</span>
        <span class="text-body-2 my-2">Pour actualiser la position des expéditions dont vous êtes en charges,</span>
        <span class="text-body-2 my-2">Avec votre appareil photo, scannez les <span class="text-body-1">QR Codes</span> ayant la forme suivante :</span>
        <img :src="qrCodeSample" width="100" class="my-4"/>
        <span class="text-title-3 my-2">Nous vous remercions pour votre collaboration !</span>
        <span class="text-title-3 my-2">L'équipe TraQKING</span>
    </div>
</template>

<script>

export default ({
    name: 'HomeView',
    data(){
        return {
            qrCodeSample: require('@/assets/qr-code-sample.png'),
        }
    },
    created(){
        this.$pauseGeoloc()
    }
})
</script>